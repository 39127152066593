import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ReactComponent as RemoveIcon } from 'assets/icons/x-small.svg';
import { SnackbarKey, useSnackbar } from 'notistack';

import { removeNotification } from 'store/modules/notifications/actions';
import { notifications } from 'store/modules/notifications/selector';

import { IconButton, SvgIcon } from '@mui/material';

const Notifications: React.FC = () => {
  const notificationItems = useSelector(notifications);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  useEffect(() => {
    notificationItems.forEach((notification) => {
      // dismiss related notifications
      if (notification.dismissId) closeSnackbar(notification.dismissId);
      enqueueSnackbar(notification.text, {
        key: notification.id,
        preventDuplicate: true,
        variant: notification.type,
        autoHideDuration: notification.duration ?? 3000,
        ...(notification?.type === 'error' && {
          action: (snackbarId: SnackbarKey) => (
            <IconButton size="small" onClick={() => closeSnackbar(snackbarId)}>
              <SvgIcon
                style={{ color: 'white' }}
                component={RemoveIcon}
                color="action"
                viewBox={'0 0 32 32'}
              />
            </IconButton>
          ),
        }),
        anchorOrigin:
          notification.type === 'default'
            ? { vertical: 'bottom', horizontal: 'center' }
            : { vertical: 'top', horizontal: 'right' },
        onExited: (_, key) => {
          dispatch(removeNotification(key as string));
        },
      });
    });
  }, [notificationItems, dispatch, enqueueSnackbar, closeSnackbar]);

  return null;
};

export default Notifications;
