import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useStoreDispatch } from 'store';
import {
  setDraftTouchedFields,
  updateDraft,
} from 'store/modules/campaign/slice';

import { CampaignSectionProps } from 'components/Campaigns/CampaignDetails/CampaignDetails';
import { NumberInput, TextInput } from 'components/Shared/CustomMui';

import {
  FormControlLabel,
  FormGroup,
  Grid,
  Switch,
  Typography,
} from '@mui/material';

interface Props extends Omit<CampaignSectionProps, 'validate'> {
  external_campaign_id: string;
  max_incentive_value: string | undefined;
}

export const Incentives = ({
  external_campaign_id,
  formState,
  max_incentive_value,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useStoreDispatch();

  const [isCouponNeeded, setIsCouponNeeded] = useState<boolean>(
    !!external_campaign_id
  );

  return (
    <>
      <Grid container rowSpacing={0} columnSpacing={{ xs: 0 }}>
        <Grid item sx={{ mb: (theme) => theme.spacing(2) }}>
          <Typography
            variant="h3"
            sx={{
              fontSize: (theme) => theme.typography.body2.fontSize,
            }}
          >
            {t('coupon')}
          </Typography>
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  checked={isCouponNeeded}
                  disabled={formState.readonlyFields.includes('Incentives')}
                  onChange={() => {
                    setIsCouponNeeded(!isCouponNeeded);
                    if (isCouponNeeded) {
                      dispatch(setDraftTouchedFields('incentive'));
                      dispatch(updateDraft({ external_campaign_id: '' }));
                    }
                  }}
                />
              }
              label={t('coupon_needed') as string}
            />
          </FormGroup>
        </Grid>
        <Grid item>
          <NumberInput
            id="max-incentive"
            label={t('max_incentive')}
            value={max_incentive_value}
            disabled={true}
            decimalScale={2}
            width="100%"
          />
        </Grid>
        <Grid item>
          <TextInput
            id="coupon-id"
            onChange={(e) =>
              dispatch(
                updateDraft({ external_campaign_id: e.target.value?.trim() })
              )
            }
            label={t('external_campaign_id')}
            value={external_campaign_id.trim()}
            disabled={!isCouponNeeded}
            width="100%"
          />
        </Grid>
      </Grid>
    </>
  );
};
