import i18n from 'i18n';
import { DraftBuildUniverseUserConditionType } from 'shared/interfaces';
import { definitions } from 'types/api';
import { randString } from 'utils';

export function mapUniverseScore(score: number): string {
  if (score < 0.3) {
    return 'specific';
  }

  if (score < 0.7) {
    return 'normal';
  }

  return 'broad';
}

export const generateProductsRuleText = (
  products: definitions['TaxonomyProduct'][]
): string => {
  const firstProduct = products[0];
  const productName = firstProduct?.product_name?.trim();
  const otherValuesText =
    products.length > 1
      ? `${i18n.t('or')} ${products.length - 1} ${i18n.t('other_products')}`
      : '';

  if (!firstProduct) return '';

  return `${productName} ${otherValuesText}`.trim();
};

export const generateAttributeRuleText = (
  attributes?: definitions['ProductTaxonomyAttribute'][],
  searchTerm?: string
): string => {
  const firstAttributeName =
    attributes && attributes?.length > 0 ? attributes[0]?.name?.trim() : '';
  const otherValuesText =
    attributes && attributes?.length > 1
      ? `${i18n.t('or')} ${attributes.length - 1} ${i18n.t('other_attributes')}`
      : '';
  const searchText = searchTerm ? `"${searchTerm}"` : '';

  if (!firstAttributeName && !searchTerm) return '';

  return `${searchText} ${firstAttributeName} ${otherValuesText}`.trim();
};

export const generateShopperMetadataRuleText = (
  shopperMetadata: definitions['ShopperMetadata'][],
  metadataIds?: string[]
): string => {
  const firstShopperMetadataItem = shopperMetadata[0];
  if (!firstShopperMetadataItem) return '';

  const attributeName = firstShopperMetadataItem?.attribute_name?.trim();

  let value = '';

  if (
    firstShopperMetadataItem?.shopper_metadata_values?.length ===
    metadataIds?.length
  )
    value = i18n.t('all') ?? '';
  else {
    const attributeValue =
      firstShopperMetadataItem?.shopper_metadata_values
        .filter((x) => x.metadata_id === metadataIds?.[0])[0]
        ?.attribute_value?.trim() ?? '';

    const otherValuesText =
      metadataIds && metadataIds?.length > 1
        ? `${i18n.t('or')} ${metadataIds?.length - 1} ${i18n.t('other_values')}`
        : '';

    value = `${attributeValue} ${otherValuesText}`;
  }

  return `${attributeName}: ${value}`.trim();
};

export const isBuilderConditionValid = (
  condition: definitions['BuildUniverseUserCondition']
): boolean => {
  return (
    !!condition.logic &&
    ((condition.attribute_id && !!condition?.metadata_ids?.length) ||
      !!condition.attributes?.length ||
      !!condition?.gtins?.length ||
      !!condition?.search_query) &&
    !!condition?.period_type
  );
};

export const EMPTY_BUILDER_CONDITION: DraftBuildUniverseUserConditionType = {
  isNew: true,
  id: randString(),
  search_query: '',
  gtins: [],
  attributes: [],
  logic: 'BUILD_UNIVERSE_CONDITION_LOGIC_INCLUDE',
  period: 1,
  period_type: 'BUILDER_CONDITION_PERIOD_TYPE_DAY',
};

export const EMPTY_RULE_SET: {
  builder_conditions: DraftBuildUniverseUserConditionType[];
} = {
  builder_conditions: [EMPTY_BUILDER_CONDITION],
};
