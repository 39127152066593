import React, { ChangeEvent, FocusEvent, ReactElement, ReactNode } from 'react';
import NumberFormat, { NumberFormatProps } from 'react-number-format';

import { ReactComponent as CircleHelpIcon } from 'assets/icons/circle-help.svg';
import classnames from 'classnames';
import { styled } from 'theme';

import {
  InputAdornment,
  InputBaseComponentProps,
  SvgIcon,
} from '@mui/material';
import FormHelperText from '@mui/material/FormHelperText';
import OutlinedInput, { OutlinedInputProps } from '@mui/material/OutlinedInput';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';

export { default as DatePicker } from './DatePicker/DatePicker';

export type InputValidationMessage = null | boolean | string | undefined;

export const FormError: React.FC = ({ children }) => (
  <FormHelperText
    className="FormError-root"
    error
    sx={{
      fontSize: 12,
      marginTop: (theme) => theme.spacing(0),
      marginBottom: (theme) => theme.spacing(0.5),
    }}
  >
    {children}
  </FormHelperText>
);

export const FormHint: React.FC = ({ children }) => (
  <FormHelperText
    sx={{
      fontSize: 12,
      marginTop: (theme) => theme.spacing(0),
      marginBottom: (theme) => theme.spacing(0.5),
    }}
  >
    {children}
  </FormHelperText>
);

export const InputS = styled('div', {
  shouldForwardProp: (prop) => prop !== 'width',
})<{ width?: number | string }>(({ width, theme }) => ({
  marginBottom: theme.spacing(4),
  position: 'relative',
  '& label': {
    color: theme.palette.text.secondary,
    display: 'block',
    fontSize: 13,
    lineHeight: '16px',
    marginBottom: theme.spacing(0.5),
  },
  '& .MuiInputBase-root': {
    width: width ?? 365,
  },
}));

export const SpanS = styled('span')(({ theme }) => ({
  color: theme.palette.error.main,
}));

export const ErrorTooltipS = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#E42912',
    color: '#FFFFFF',
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
    maxWidth: 150,
  },
  zIndex: 0,
}));

interface TextInputProps extends Omit<OutlinedInputProps, 'error'> {
  className?: string;
  disabled?: boolean;
  error?: InputValidationMessage;
  hint?: string | ReactNode;
  inputProps?: InputBaseComponentProps;
  label: string;
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  value: string;
  id?: string;
  width?: number | string;
  sx?: SxProps<Theme>;
}
export const TextInput: React.FC<TextInputProps> = (props) => {
  const {
    className,
    disabled,
    inputProps,
    label,
    error,
    hint,
    onBlur,
    onChange,
    placeholder,
    value,
    id,
    width,
    sx,
    ...rest
  } = props;
  return (
    <InputS className={className} width={width} sx={sx}>
      <label htmlFor={id}>
        {label}
        {rest.required && <SpanS> *</SpanS>}
      </label>
      <OutlinedInput
        id={id}
        disabled={disabled}
        error={!!error}
        fullWidth
        inputProps={inputProps}
        margin="dense"
        onBlur={onBlur}
        onChange={onChange}
        placeholder={placeholder}
        value={value}
        {...rest}
      />
      {!!error && typeof error === 'string' && <FormError>{error}</FormError>}
      {!!hint && !error && <FormHint>{hint}</FormHint>}
    </InputS>
  );
};

const NumberFormatCustom = React.forwardRef((props: any, ref) => {
  const { onChange, ...rest } = props;
  const thousandSeparator =
    typeof rest.thousandSeparator === 'undefined'
      ? '.'
      : rest.thousandSeparator;
  return (
    <NumberFormat
      {...rest}
      allowLeadingZeros={false}
      allowNegative={false}
      decimalSeparator={','}
      getInputRef={ref}
      isAllowed={(values) => {
        const { value } = values;
        return Number(value) <= props.max;
      }}
      isNumericString
      onValueChange={(values) => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
      thousandSeparator={thousandSeparator}
    />
  );
});
interface NumberInputBaseProps extends NumberFormatProps {
  disabled?: boolean;
  error?: InputValidationMessage;
  max?: number;
  sx?: SxProps<Theme>;
  suffixIcon?: ReactElement;
}
export const NumberInputBase: React.FC<NumberInputBaseProps> = (props) => {
  const {
    decimalScale = 0,
    disabled = false,
    error,
    max = 9999999999,
    onBlur,
    onChange,
    sx,
    value,
    suffixIcon,
    prefix,
    suffix,
    ...rest
  } = props;
  return (
    <OutlinedInput
      className="NumberInputBase-root"
      disabled={disabled}
      error={!!error}
      fullWidth
      inputComponent={NumberFormatCustom}
      inputProps={{
        ...rest,
        ...(!!prefix && { prefix: `${prefix} ` }),
        ...(!!suffix && { suffix: ` ${suffix}` }),
        decimalScale,
        max,
      }}
      onBlur={onBlur}
      onChange={onChange}
      sx={sx}
      value={value}
      endAdornment={
        suffixIcon && (
          <InputAdornment position="end">{suffixIcon}</InputAdornment>
        )
      }
    />
  );
};
interface NumberInputProps extends NumberInputBaseProps {
  className?: string;
  label?: string;
  helper?: string;
  id?: string;
  width?: number | string;
  sx?: SxProps<Theme>;
}
export const NumberInput: React.FC<NumberInputProps> = (props) => {
  const { className, width, id, error, label, helper, sx, ...rest } = props;
  return (
    <InputS
      width={width}
      sx={{ ...sx }}
      className={classnames('NumberInput-root', className)}
    >
      <label htmlFor={id}>
        {label}
        {rest.required && <SpanS> *</SpanS>}
      </label>
      <NumberInputBase id={id} error={!!error} {...rest} />
      {error && typeof error === 'string' && <FormError>{error}</FormError>}
      {!error && helper && <FormHelperText>{helper}</FormHelperText>}
    </InputS>
  );
};

export const Info: React.FC<{ text: string }> = (props) => {
  const { text } = props;
  return (
    <Tooltip disableInteractive title={text} placement="right">
      <SvgIcon
        component={CircleHelpIcon}
        sx={{
          color: (theme) => theme.palette.grey[300],
          marginLeft: (theme) => theme.spacing(1),
          zIndex: 500,
        }}
      />
    </Tooltip>
  );
};
