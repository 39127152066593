import { ReactNode } from 'react';

import {
  MenuItem,
  Select,
  SelectChangeEvent,
  SxProps,
  Theme,
} from '@mui/material';

import { FormError, InputS, InputValidationMessage, SpanS } from '../form';

interface SelectInputProps {
  name?: string;
  label: string;
  value: string;
  htmlFor?: string;
  className?: string;
  disabled?: boolean;
  required: boolean;
  sx?: SxProps<Theme>;
  width?: number | string;
  error?: InputValidationMessage;
  items: { label: ReactNode; value: string; disabled?: boolean }[];
  onChange: (e: SelectChangeEvent<string>) => void;
  disableScrollLock?: boolean;
}

export const SelectInput: React.FC<SelectInputProps> = (props) => {
  const {
    className,
    width,
    label,
    error,
    items,
    onChange,
    value,
    disabled,
    htmlFor,
    required,
    sx,
    name,
    disableScrollLock,
    ...rest
  } = props;
  return (
    <InputS className={className} width={width ?? 365}>
      <label htmlFor={htmlFor}>
        {label}
        {required && <SpanS> *</SpanS>}
      </label>
      <Select
        id={htmlFor}
        name={name}
        displayEmpty={true}
        onChange={onChange}
        value={value}
        variant="outlined"
        disabled={disabled}
        MenuProps={{ disableScrollLock: disableScrollLock }}
        sx={{ ...sx }}
        {...rest}
      >
        {items.map(({ label, value, disabled = false }) => (
          <MenuItem key={value} value={value} disabled={disabled}>
            {label}
          </MenuItem>
        ))}
      </Select>
      {!!error && <FormError>{error}</FormError>}
    </InputS>
  );
};
