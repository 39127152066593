import NumberFormat, { NumberFormatProps } from 'react-number-format';

export const CustomNumberFormat = ({
  prefix,
  suffix,
  ...rest
}: NumberFormatProps) => {
  return (
    <NumberFormat
      {...(!!prefix && { prefix: `${prefix} ` })}
      {...(!!suffix && { suffix: ` ${suffix}` })}
      {...rest}
    />
  );
};
