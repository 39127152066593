import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/react';
import { DEFAULT_API_PARAMETERS } from 'shared/constants';
import { Endpoints } from 'types';
import { definitions, operations } from 'types/api';

import { RootState } from 'store';

import { baseApi } from '../baseApi';

/**
 * Enhance baseApi with tags that we use only in universes
 */
const enhancedBaseApiWithTags = baseApi.enhanceEndpoints({
  addTagTypes: ['UniverseList', 'Universe', 'Estimation', 'UniverseChangeLog'],
});

// inject universes endpoints to the enhancedBaseApiWithTags
export const universeApi = enhancedBaseApiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getUniverses: builder.query<
      definitions['GetAllUniversesResponse'],
      operations['UniverseService_GetAllUniverses']['parameters']['query']
    >({
      query: (
        params: operations['UniverseService_GetAllUniverses']['parameters']['query']
      ) => ({
        url: Endpoints.universes,
        params: { per_page: DEFAULT_API_PARAMETERS.per_page, ...params },
      }),
      transformResponse: (response: definitions['GetAllUniversesResponse']) => {
        return {
          ...response,
          universes: response.universes.map((universe) => ({
            ...universe,
            build: {
              ...universe?.build,
              rule_sets: universe?.build?.rule_sets,
            },
          })),
        };
      },
      providesTags: ['UniverseList'],
    }),
    getUniverseById: builder.query<
      definitions['Universe'],
      { id: definitions['Universe']['id']; silentNotification: boolean }
    >({
      async queryFn(
        { id, silentNotification },
        { getState },
        _extraOptions,
        fetchWithBQ
      ) {
        const res = await fetchWithBQ({
          url: `${Endpoints.universes}/${id}`,
        });
        const resultData = res.data as definitions['GetUniverseByIDResponse'];

        return res.data
          ? {
              data: {
                ...resultData.universe,
                build: {
                  ...resultData?.universe?.build,
                  rule_sets: resultData?.universe?.build?.rule_sets,
                },
              },
            }
          : {
              error: {
                ...res.error,
                data: {
                  ...(res.error?.data as any),
                  silentNotification: silentNotification,
                },
              } as FetchBaseQueryError,
            };
      },
      providesTags: (_result, _error, { id }) => [{ type: 'Universe', id }],
    }),
    createUniverse: builder.mutation<
      definitions['CreateUniverseResponse'],
      Omit<definitions['Universe'], 'id'>
    >({
      async queryFn(universe, _queryApi, _extraOptions, fetchWithBQ) {
        universe.build.score = '';

        let response = await fetchWithBQ({
          url: Endpoints.universes,
          method: 'POST',
          body: { universe },
        });

        const createUniverseResponse =
          response.data as definitions['CreateUniverseResponse'];

        return !!createUniverseResponse
          ? {
              data: createUniverseResponse,
            }
          : { error: response.error as FetchBaseQueryError };
      },
      invalidatesTags: ['UniverseList', 'Universe'],
    }),
    updateUniverse: builder.mutation<
      definitions['CreateUniverseResponse'],
      definitions['Universe']
    >({
      async queryFn(universe, _queryApi, _extraOptions, fetchWithBQ) {
        const { id, advertiser_id, retailer_id, country_code, ...rest } =
          universe;
        let response = await fetchWithBQ({
          url: `${Endpoints.universes}/${id}`,
          method: 'PUT',
          body: { universe: rest },
        });

        return response.data
          ? {
              data: {
                universe,
                message: (
                  response?.data as definitions['UpdateUniverseResponse']
                )?.message,
              },
            }
          : { error: response.error as FetchBaseQueryError };
      },
      invalidatesTags: (result) =>
        result
          ? [
              { type: 'UniverseChangeLog', id: result.universe.id },
              'UniverseList',
            ]
          : [],
    }),
    deleteUniverse: builder.mutation<string, definitions['Universe']['id']>({
      query: (id: string) => ({
        url: `${Endpoints.universes}/${id}`,
        method: 'DELETE',
      }),
      transformResponse: (_response: string, _meta, args) => {
        return args;
      },
      invalidatesTags: ['UniverseList', 'Universe'],
    }),
    getEstimation: builder.query<
      { score: string },
      Omit<definitions['GetShoppersEstimationUserRequest'], 'network_id'>
    >({
      async queryFn(payload, { getState }, _extraOptions, fetchWithBQ) {
        const rootState = getState() as RootState;
        const advertiser = rootState.settings.advertiser;
        if (!advertiser) {
          throw new Error('No advertiser');
        }

        const response = await fetchWithBQ({
          url: '/v1/shoppers-estimation',
          method: 'POST',
          body: {
            network_id: advertiser.network_id,
            ...payload,
          },
        });

        const getEstimationResponse =
          response.data as definitions['GetShoppersEstimationUserResponse'];

        return response.data
          ? {
              data: { score: getEstimationResponse.score },
            }
          : { error: response.error as FetchBaseQueryError };
      },
      providesTags: ['Estimation'],
    }),
    getUniverseChangeLog: builder.query<
      definitions['GetUniverseChangeLogResponse'],
      operations['UniverseService_GetUniverseChangeLog']['parameters']
    >({
      query: (
        params: operations['UniverseService_GetUniverseChangeLog']['parameters']
      ) => ({
        url: `${Endpoints.universes}/${params.path.universe_id}/changelog`,
        params: { page: params.query.page, per_page: params.query.per_page },
      }),
      providesTags: (_result, _error, params) => [
        { type: 'UniverseChangeLog', id: params.path.universe_id },
      ],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetUniversesQuery,
  useLazyGetUniversesQuery,
  useGetUniverseByIdQuery,
  useLazyGetUniverseByIdQuery,
  useCreateUniverseMutation,
  useUpdateUniverseMutation,
  useDeleteUniverseMutation,
  useGetEstimationQuery,
  useGetUniverseChangeLogQuery,
  useLazyGetEstimationQuery,
} = universeApi;
