import { useCallback, useEffect, useState } from 'react';

import { CAMPAIGN_READONLY_STATES } from 'shared/constants';
import { FormType, UserRole } from 'types';
import { definitions } from 'types/api';

import {
  CampaignFormActionType,
  getFormActions,
} from './CampaignStateManagement/actionMapping';

const formFields = [
  'Name',
  'Budget',
  'CPM',
  'CPA',
  'CPC',
  'TargetDailyBudget',
  'Country',
  'Universes',
  'Incentives',
  'Channels',
  'Products',
  'Creatives',
  'CampaignWindow',
  'RejectReason',
  'SuccessPrediction',
  'FrequencyCap',
  'FrequencyCapTimeUnit',
  'FrequencyCapValue',
  'CampaignGroup',
  'BidModifier',
] as const;

export type FormFieldType = typeof formFields[number];

export type CampaignFormStatus = {
  showValidations: boolean;
  readonlyFields: FormFieldType[];
  formActions: CampaignFormActionType[];
  hiddenFields: FormFieldType[];
  campaignState?: definitions['CampaignState'];
  formType: FormType;
};

interface Props {
  formType: FormType;
  state: definitions['CampaignState'];
  type?: definitions['CampaignType'];
  userRole: UserRole;
}

export const useCampaignFormState = ({
  formType,
  state: campaignState,
  type,
  userRole,
}: Props): CampaignFormStatus => {
  const [showValidations, setShowValidations] = useState(true);
  const [readonlyFields, setReadonlyFields] = useState<FormFieldType[]>([]);
  const [formActions, setFormActions] = useState<CampaignFormActionType[]>([]);
  const [hiddenFields, setHiddenFields] = useState<FormFieldType[]>([]);
  const isDraftOrRejectedCampaign =
    campaignState &&
    ['CAMPAIGN_STATE_DRAFT', 'CAMPAIGN_STATE_REJECTED'].includes(campaignState);

  const isReadOnlyCampaign =
    campaignState && CAMPAIGN_READONLY_STATES.includes(campaignState);

  const isASubmittedCampaignAConversion =
    campaignState === 'CAMPAIGN_STATE_SUBMITTED' &&
    type === 'CAMPAIGN_TYPE_CONVERSION';

  /**
   * Set the form actions based on the state and user role
   */
  const manageFormActions = useCallback((): void => {
    if (formType === 'view') {
      setFormActions([]);
      return;
    }
    let allowedActions: CampaignFormActionType[] = getFormActions(
      userRole,
      campaignState
    );

    // users should not be able to clone a not created campaign (in create mode)
    if (formType === 'create')
      allowedActions = allowedActions.filter((a) => a !== 'clone');

    setFormActions(allowedActions);
  }, [type, userRole, campaignState]);

  /**
   * Set readonly fields based on user role and campaign state.
   */
  const manageReadonlyFormFields = useCallback((): void => {
    let fieldsToReadonly: FormFieldType[] = [];
    const runningStateEnabledFields: FormFieldType[] = [
      'Budget',
      'Universes',
      'TargetDailyBudget',
      'BidModifier',
      'CPM',
      'CPC',
    ];

    switch (userRole) {
      case UserRole.APPROVAL_MANAGER:
      case UserRole.SALESFORCE_MANAGER: {
        fieldsToReadonly = fieldsToReadonly.concat(formFields);
        if (isASubmittedCampaignAConversion) {
          fieldsToReadonly = fieldsToReadonly.filter(
            (field) => field !== 'Incentives'
          );
        }
        break;
      }
      default: {
        if (isReadOnlyCampaign) {
          fieldsToReadonly = fieldsToReadonly.concat(formFields);
        } else if (!isDraftOrRejectedCampaign) {
          fieldsToReadonly = fieldsToReadonly.concat(
            formFields.filter(
              (field) => !runningStateEnabledFields.includes(field)
            )
          );
        }
      }
    }

    setReadonlyFields(fieldsToReadonly);
  }, [formType, userRole, type, campaignState]);

  /**
   * Show/hide fields based on user role or campaign type
   */
  const manageHiddenFormFields = useCallback((): void => {
    let hiddenFields: FormFieldType[] = [];
    if (
      !!type &&
      ['CAMPAIGN_TYPE_CONVERSION', 'CAMPAIGN_TYPE_CLICK'].includes(type)
    ) {
      hiddenFields.push('FrequencyCap');
    }
    if (
      (campaignState !== 'CAMPAIGN_STATE_DRAFT' &&
        campaignState !== 'CAMPAIGN_STATE_PAUSED_MANUAL' &&
        campaignState !== 'CAMPAIGN_STATE_PAUSED_PRODUCT_UNAVAILABLE' &&
        campaignState !== 'CAMPAIGN_STATE_PAUSED_BUDGET_DEPLETED') ||
      type === 'CAMPAIGN_TYPE_CLICK'
    )
      hiddenFields.push('SuccessPrediction');

    if (type === 'CAMPAIGN_TYPE_CONVERSION')
      hiddenFields.push('TargetDailyBudget', 'CPM', 'CPC');

    if (type === 'CAMPAIGN_TYPE_IMPRESSION') hiddenFields.push('CPC', 'CPA');

    if (type === 'CAMPAIGN_TYPE_CLICK') hiddenFields.push('CPA', 'CPM');

    if (
      userRole === UserRole.ADVERTISER_CAMPAIGN_MANAGER ||
      userRole === UserRole.ADVERTISER_ADMIN
    ) {
      hiddenFields = hiddenFields.concat([
        'Incentives',
        'RejectReason',
        'BidModifier',
      ]);
    } else if (userRole === UserRole.KEY_ACCOUNT_MANAGER) {
      // should only display reject reason if campaign is rejected
      if (campaignState === 'CAMPAIGN_STATE_REJECTED') {
        hiddenFields.push('Incentives');
      } else hiddenFields = hiddenFields.concat(['Incentives', 'RejectReason']);
    } else if (
      userRole === UserRole.SALESFORCE_MANAGER ||
      userRole === UserRole.APPROVAL_MANAGER
    ) {
      hiddenFields = hiddenFields.concat(['RejectReason', 'BidModifier']); //PACMAN-2120
      if (type === 'CAMPAIGN_TYPE_IMPRESSION') {
        hiddenFields.push('Incentives');
      }
    }
    setHiddenFields(hiddenFields);
  }, [formType, UserRole, type, campaignState]);

  useEffect(() => {
    if (formType === 'view') setShowValidations(false);
    manageReadonlyFormFields();
    manageHiddenFormFields();
    manageFormActions();
  }, [campaignState, formType, userRole]);

  return {
    showValidations,
    readonlyFields,
    formActions,
    hiddenFields,
    campaignState,
    formType,
  };
};
