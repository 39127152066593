import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/react';
import _, { differenceWith, fromPairs, omit, toPairs } from 'lodash';
import { DEFAULT_API_PARAMETERS } from 'shared/constants';
import {
  Campaign,
  CampaignsRequest,
  CampaignsResponse,
} from 'shared/interfaces/Campaign';
import { Endpoints } from 'types';
import { definitions, operations } from 'types/api';

import { RootState } from 'store';
import {
  formatDates,
  getMimeTypeForApi,
  parseDates,
} from 'store/modules/campaign/helpers';

import { baseApi } from '../baseApi';

/**
 * Enhance baseApi with tags that we use only in campaign
 */
const enhancedBaseApiWithTags = baseApi.enhanceEndpoints({
  addTagTypes: ['CampaignList', 'Campaign', 'CampaignChangeLog'],
});

const uploadCreative = async (
  signed_url: string,
  file: File,
  max_file_size: number
): Promise<void> => {
  fetch(signed_url, {
    headers: {
      'Content-Type': file.type,
      'x-goog-content-length-range': `0,${max_file_size}`,
    },
    method: 'PUT',
    body: file,
  });
};

// inject campaign endpoints to the enhancedBaseApiWithTags
export const campaignApi = enhancedBaseApiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getCampaigns: builder.query<
      CampaignsResponse,
      CampaignsRequest | undefined
    >({
      async queryFn(params, _queryApi, _extraOptions, fetchWithBQ) {
        const query = {
          ...params?.filter,
          page: params?.page ?? 1,
          search: params?.filter?.search || '',
          per_page: params?.per_page ?? DEFAULT_API_PARAMETERS.per_page,
          sort_by: params?.sortBy ?? 'CAMPAIGN_SORT_UNKNOWN',
          order: params?.order ?? 'SORT_ORDER_UNKNOWN',
        };

        const response = await fetchWithBQ({
          url: Endpoints.campaigns,
          params: query,
        });

        const responseData =
          response.data as definitions['GetAllCampaignsResponse'];

        return response.data
          ? {
              data: {
                query: {
                  ...responseData.pagination,
                  per_page: responseData.pagination.per_page,
                  sortBy: query.sort_by,
                  order: query.order,
                },
                results: responseData.campaigns.map((c) => parseDates(c)),
              },
            }
          : { error: response.error as FetchBaseQueryError };
      },
      providesTags: ['CampaignList'],
    }),
    getCampaignById: builder.query<Campaign, string>({
      query: (id: string) => `${Endpoints.campaigns}/${id}`,
      transformResponse: (response: definitions['GetCampaignByIDResponse']) => {
        return parseDates(response.campaign);
      },
      providesTags: (_result, _error, id) => [{ type: 'Campaign', id }],
    }),
    createCampaign: builder.mutation<Campaign, void>({
      async queryFn(_args, { getState }, _extraOptions, fetchWithBQ) {
        const rootState = getState() as RootState;
        const advertiserId = rootState.user.user?.advertiser_id;
        if (!advertiserId) {
          throw new Error('No advertiser id');
        }
        const draft = formatDates(rootState.campaigns.draft as Campaign);
        draft.advertiser_id = advertiserId;

        // create campaign first to get its id
        const { id: _id, created_at, updated_at, creatives, ...r } = draft;

        const response = await fetchWithBQ({
          url: Endpoints.campaigns,
          method: 'POST',
          body: { ...r },
        });

        const createCampaignsResponse =
          response.data as definitions['CreateCampaignResponse'];

        // upload creatives
        await Promise.all(
          draft.creatives?.map(async (creative, index) => {
            createCampaignsResponse.campaign.creatives[index] = {
              ...creative,
              mime_type: 'CREATIVE_MIME_TYPE_UNKNOWN',
            };
            if (!creative.file_changed) {
              return;
            }
            const { file } = creative;
            if (file) {
              const { cdn_url, max_file_size, signed_url } = (
                await fetchWithBQ({
                  url: `${Endpoints.campaigns}/signed_url`,
                  params: {
                    campaign_id: createCampaignsResponse.campaign.id,
                    mime_type: getMimeTypeForApi(file.file.type),
                  },
                })
              ).data as definitions['GetSignedURLForCreativeResponse'];

              await uploadCreative(signed_url, file.file, max_file_size);

              createCampaignsResponse.campaign.creatives[index] = {
                ...creative,
                url: cdn_url,
              };
            }
          })
        );

        // we need to update previously created campaign with the new creative url

        await fetchWithBQ({
          url: `${Endpoints.campaigns}/${createCampaignsResponse.campaign.id}`,
          method: 'PATCH',
          body: { creatives: createCampaignsResponse.campaign.creatives },
        });

        return response.data
          ? { data: createCampaignsResponse.campaign }
          : { error: response.error as FetchBaseQueryError };
      },
      invalidatesTags: ['CampaignList'],
    }),
    updateCampaign: builder.mutation<Campaign, void | Campaign>({
      async queryFn(args, { getState }, _extraOptions, fetchWithBQ) {
        const draft = args
          ? formatDates(args)
          : formatDates((getState() as RootState).campaigns.draft as Campaign);

        // upload creatives
        await Promise.all(
          draft.creatives?.map(async (creative, index) => {
            if (!creative.file_changed) {
              return;
            }
            const { file } = creative;
            if (file) {
              const { cdn_url, max_file_size, signed_url } = (
                await fetchWithBQ({
                  url: `${Endpoints.campaigns}/signed_url`,
                  params: {
                    campaign_id: draft.id,
                    mime_type: getMimeTypeForApi(file.file.type),
                  },
                })
              ).data as definitions['GetSignedURLForCreativeResponse'];

              await uploadCreative(signed_url, file.file, max_file_size);

              draft.creatives = [
                ...draft.creatives.slice(0, index),
                {
                  ...creative,
                  url: cdn_url,
                },
                ...draft.creatives.slice(index + 1),
              ];
            }
          })
        );

        const changes = differenceWith(
          toPairs(draft),
          toPairs(
            formatDates(
              (getState() as RootState).campaigns.originalCampaign as Campaign
            )
          ),
          _.isEqual
        );

        const updated = omit(fromPairs(changes), [
          'universe',
          'extended_channels',
          'additionally_promoted_products',
          'used_budget_total_cumulative',
        ]);

        const response = await fetchWithBQ({
          url: `${Endpoints.campaigns}/${draft.id}`,
          method: 'PATCH',
          body: updated,
        });

        return response.data
          ? { data: draft }
          : { error: response.error as FetchBaseQueryError };
      },
      invalidatesTags: (result) =>
        result
          ? [
              { type: 'Campaign', id: result.id },
              'CampaignList',
              'CampaignChangeLog',
            ]
          : [],
    }),
    patchCampaignFields: builder.mutation<
      Partial<Campaign>,
      Partial<Campaign> & Pick<Campaign, 'id'>
    >({
      query: ({ id, ...patch }) => ({
        url: `${Endpoints.campaigns}/${id}`,
        method: 'PATCH',
        body: patch,
      }),
      transformResponse: (_response: string, _meta, args) => {
        return args;
      },
    }),
    deleteCampaign: builder.mutation<string, string>({
      query: (id: string) => ({
        url: `${Endpoints.campaigns}/${id}`,
        method: 'DELETE',
      }),
      transformResponse: (_response: string, _meta, args) => {
        return args;
      },
      invalidatesTags: ['CampaignList'],
    }),
    cloneCampaign: builder.mutation<
      definitions['CloneCampaignResponse'],
      operations['CampaignService_CloneCampaign']['parameters']
    >({
      query: (
        params: operations['CampaignService_CloneCampaign']['parameters']
      ) => ({
        url: `${Endpoints.campaigns}/${params?.path.source_id}/clone`,
        method: 'POST',
        body: {
          name: params?.body.body?.name,
          abTest: params?.body?.body?.abTest,
        },
      }),
      invalidatesTags: ['CampaignList'],
    }),
    getChangeLog: builder.query<
      definitions['GetChangeLogResponse'],
      { campaignId: string }
    >({
      query: (requestParams: { campaignId: string }) => ({
        url: `${Endpoints.campaigns}/${requestParams?.campaignId}/changelog`,
      }),
      providesTags: ['CampaignChangeLog'],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetCampaignsQuery,
  useLazyGetCampaignsQuery,
  useGetCampaignByIdQuery,
  useLazyGetCampaignByIdQuery,
  useCreateCampaignMutation,
  useUpdateCampaignMutation,
  usePatchCampaignFieldsMutation,
  useDeleteCampaignMutation,
  useCloneCampaignMutation,
  useGetChangeLogQuery,
} = campaignApi;
