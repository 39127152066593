import { FC, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { ReactComponent as DeleteIcon } from 'assets/icons/trash.svg';
import { ReactComponent as CrossIcon } from 'assets/icons/x-small.svg';
import { DEFAULT_COUNTRY } from 'shared/constants';
import { UserRole } from 'types';
import { definitions } from 'types/api';
import { toDateTime } from 'utils/time';

import { selectCountries } from 'store/modules/settings/selectors';

import { AccessResolver } from 'components/Shared/AccessResolver/AccessResolver';
import { CountryNameWithFlag } from 'components/Shared/CountryNameWithFlag/CountryNameWithFlag';

import { Chip, IconButton, Stack, SvgIcon, Tooltip } from '@mui/material';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { mapUniverseScore } from '../utils';
import styles from './UniverseItem.module.scss';
import { UniverseItemField, UniverseItemFooter } from './components';

interface UniverseItemProps {
  onClick: (universe: definitions['Universe']) => void;
  onRemove?: (universe: definitions['Universe']) => void;
  universe: definitions['Universe'];
  deletable?: boolean;
  readonly: boolean;
  retailers: definitions['Retailer'][];
  mode: 'select' | 'edit' | undefined;
}

export const UniverseItem: FC<UniverseItemProps> = (props): ReactElement => {
  const { onClick, onRemove, universe, readonly, deletable, retailers, mode } =
    props;
  const { build, name } = universe;
  const { t } = useTranslation();
  const countries = useSelector(selectCountries);

  const getRetailerDisplayName = (retailerId: string): string =>
    retailers.find(
      (retailer: definitions['Retailer']) => retailer.id === retailerId
    )?.display_name || '-';

  const getScoreValueBasedOnBuilderType = (score: string): string => {
    return build.type === 'UNIVERSE_BUILD_TYPE_IMPORT'
      ? score
      : mapUniverseScore(Number(score));
  };

  const getCountryName = (countryCode: string): string => {
    if (countries && countries.length) {
      const country = countries.find((country) => country.code === countryCode);
      return country ? country.name : '-';
    } else {
      return DEFAULT_COUNTRY.label;
    }
  };

  return (
    <Grid
      className={
        styles.universe_item + ' ' + (readonly ? styles.read_only : '')
      }
      {...(!readonly && { onClick: () => onClick(universe) })}
    >
      <Grid container className={styles.header_container}>
        <Grid item flexGrow={1}>
          <div className={styles.title_container}>
            {name !== '' && (
              <Tooltip title={name} placement="top">
                <Typography className={styles.universe_name} variant="inherit">
                  {name}
                </Typography>
              </Tooltip>
            )}

            {onRemove && deletable && (
              <Tooltip
                title={
                  mode === 'select'
                    ? t('remove') || 'Remove'
                    : t('delete') || 'Delete'
                }
              >
                <IconButton
                  onClick={(event) => {
                    event.stopPropagation();
                    onRemove(universe);
                  }}
                >
                  <SvgIcon
                    component={mode === 'select' ? CrossIcon : DeleteIcon}
                    viewBox={'0 0 32 32'}
                  />
                </IconButton>
              </Tooltip>
            )}
          </div>
          <Stack
            direction="row"
            justifyItems="center"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography
              color="textSecondary"
              variant="body2"
              data-testid="last-modified"
            >
              {t('last_modified')}: {toDateTime(universe.updated_at)}
            </Typography>

            {!!build.rule_sets_no_treatment_percentage && (
              <Chip
                label={t('ab_test')}
                size="small"
                variant="outlined"
                color="primary"
              />
            )}
          </Stack>
        </Grid>
      </Grid>

      <Divider />

      <Grid container className={styles.description_container}>
        {/*Country*/}
        <UniverseItemField
          field={t('country')}
          value={
            <CountryNameWithFlag
              countryCode={universe?.country_code}
              countryName={getCountryName(universe?.country_code)}
            />
          }
        />

        {/*Retailer*/}
        <UniverseItemField
          field={t('retailer')}
          value={getRetailerDisplayName(universe?.retailer_id)}
        />

        {/*Audience*/}
        {build.type !== 'UNIVERSE_BUILD_TYPE_EXTERNAL' && (
          <UniverseItemField
            field={
              build.type === 'UNIVERSE_BUILD_TYPE_IMPORT'
                ? t('number_of_shoppers')
                : build.type === 'UNIVERSE_BUILD_TYPE_BUILDER'
                ? t('audience')
                : ''
            }
            value={
              build.score
                ? getScoreValueBasedOnBuilderType(build.score)
                : t('calculation_in_progress')
            }
          />
        )}

        <AccessResolver
          requiredRoles={[UserRole.KEY_ACCOUNT_MANAGER]}
          additionalCondition={!!build.rule_sets_no_treatment_percentage}
        >
          <UniverseItemField
            field={t('control_group')}
            value={`${build.rule_sets_no_treatment_percentage}%`}
          />
        </AccessResolver>
      </Grid>
      <Grid container>
        <Divider />
      </Grid>
      <Grid container className={styles.rules_container}>
        <UniverseItemFooter
          universe={universe}
          universeItemType={
            build.status === 'UNIVERSE_BUILDER_STATUS_FAIL'
              ? 'UNIVERSE_BUILDER_STATUS_FAIL'
              : build.type
          }
        ></UniverseItemFooter>
      </Grid>
    </Grid>
  );
};
