import { HTMLAttributes, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';

import { ReactComponent as RightChevronIcon } from 'assets/icons/chevron-right.svg';
import { tkCampaignType } from 'i18n/translationKeyMaps';
import { useSnackbar } from 'notistack';
import {
  CSP_CALCULATION_FINISHED_NOTIFICATION_DURATION,
  CSP_CALCULATION_STARTED_NOTIFICATION_DURATION,
} from 'shared/constants/campaign-success-prediction';
import { ExtendedCampaign } from 'shared/interfaces/Campaign';
import { FormType } from 'types';
import { definitions } from 'types/api';
import { isCampaignActive } from 'types/helpers';
import { useFeature } from 'utils/FeatureFlags';
import { toDateTime } from 'utils/time';

import { PrivateRoutes } from 'routes/PrivateRoutes';
import { Routes } from 'routes/Routes';

import { useStoreDispatch } from 'store';
import { addNotification } from 'store/modules/notifications/actions';

import { CampaignFormActionType } from 'hooks/CampaignStateManagement/actionMapping';
import { CampaignFormStatus } from 'hooks/useCampaignFormState';

import { Incentives } from 'components/Campaigns/CampaignDetails/sections/Incentives';
import { AccessResolver } from 'components/Shared/AccessResolver/AccessResolver';
import { CampaignStateChip } from 'components/Shared/CustomMui';

import { Card, Grid, Link, Stack, SvgIcon, Typography } from '@mui/material';
import Divider from '@mui/material/Divider';

import styles from './Overview.module.scss';
import {
  CampaignOverviewActions,
  CampaignOverviewBidModifier,
  CampaignOverviewUsedBudgetBar,
  CampaignSuccessPrediction,
  OverviewSection,
} from './components';

type Props = {
  campaign: ExtendedCampaign;
  state: definitions['CampaignState'];
  disabled?: boolean;
  disableSave?: boolean;
  disableSubmit?: boolean;
  formType: FormType;
  formState: CampaignFormStatus;
  cspExecutionId?: string;
  prevCSPExecutionId?: string;
  cloneCampaign: () => void;
} & HTMLAttributes<HTMLDivElement>;

export const CampaignOverview = ({
  campaign,
  formType,
  state,
  cloneCampaign,
  formState,
  children,
  cspExecutionId,
  prevCSPExecutionId,
}: Props) => {
  const { t } = useTranslation();

  const dispatch = useStoreDispatch();
  const { closeSnackbar } = useSnackbar();

  const isCSPEnabled = useFeature('isCSPEnabled') as boolean;
  const isBidModifierEnabled = useFeature('isBidModifierEnabled') as boolean;

  const getOverviewTitle = (formType: FormType): string => {
    switch (formType) {
      case 'create':
      case 'edit':
      case 'view':
        return t('campaign_overview');
      case 'validate':
        return t('approval');
      default:
        return '';
    }
  };

  const calculationMetricLabel =
    campaign.type === 'CAMPAIGN_TYPE_CONVERSION'
      ? t('expected_units_purchased')
      : t('expected_views');

  // dismiss CSP notifications on navigation
  useEffect(
    () => () => {
      closeSnackbar('csp-start-calculation-message');
    },
    []
  );

  return (
    <Card className={styles.overview_card}>
      <Grid container className={styles.overview_title}>
        <Grid item xs={12}>
          <Typography variant="h3">{getOverviewTitle(formType)}</Typography>
        </Grid>
        {campaign.updated_at && (
          <Grid item xs={12}>
            <Typography variant="body2" color={'textSecondary'}>
              {`${t('last_modified')}: ${toDateTime(campaign.updated_at)}`}
            </Typography>
          </Grid>
        )}
        <Grid item xs={12} className={styles.overview_actions_container}>
          <CampaignOverviewActions
            formState={formState}
            cloneCampaign={cloneCampaign}
          />
        </Grid>
      </Grid>
      <Divider />

      <Grid container>
        <Grid item xs={6}>
          <OverviewSection label={t('status')} bottomDivider={false}>
            <CampaignStateChip state={state} />
          </OverviewSection>
        </Grid>
        <Grid item xs={6}>
          {(formType === 'edit' || formType === 'create') && (
            <OverviewSection label={t('campaign_type')} bottomDivider={false}>
              <span data-testid="campaignType">
                {tkCampaignType(campaign.type)}
              </span>
            </OverviewSection>
          )}
        </Grid>
      </Grid>
      {isCSPEnabled && !formState.hiddenFields.includes('SuccessPrediction') && (
        <OverviewSection
          label={t('csp_generic_title')}
          bottomDivider={false}
          variant={'h3'}
          infoTooltip={t('csp_generic_tooltip')}
        >
          <CampaignSuccessPrediction
            campaign={campaign}
            calculationMetricLabel={calculationMetricLabel}
            executionId={cspExecutionId || prevCSPExecutionId}
            calculationFinished={(oldValue, newValue) => {
              if (!cspExecutionId) return;
              if (!newValue) {
                dispatch(
                  addNotification({
                    dismissId: 'csp-start-calculation-message',
                    duration: CSP_CALCULATION_FINISHED_NOTIFICATION_DURATION,
                    text: t('calculation_failed'),
                    type: 'warning',
                  })
                );
                return;
              }
              dispatch(
                addNotification({
                  dismissId: 'csp-start-calculation-message',
                  duration: CSP_CALCULATION_FINISHED_NOTIFICATION_DURATION,
                  text: t(`calculation_finished`, {
                    metric: calculationMetricLabel.toLowerCase(),
                    from: oldValue ? `from ${oldValue}` : '',
                    to: newValue,
                  }),
                  type: 'default',
                })
              );
            }}
            calculationStarted={() => {
              if (!cspExecutionId) return;
              dispatch(
                addNotification({
                  id: 'csp-start-calculation-message',
                  duration: CSP_CALCULATION_STARTED_NOTIFICATION_DURATION,
                  text: t(`calculation_started`),
                  type: 'default',
                })
              );
            }}
            universeBuildType={campaign.universe?.build?.type}
          />
        </OverviewSection>
      )}

      <AccessResolver
        requiredRoles={PrivateRoutes.REPORTING.roles}
        additionalCondition={isCampaignActive(campaign.state)}
      >
        <Grid item xs={12} className={styles.reporting_link_wrapper}>
          <Link
            component={RouterLink}
            to={`${Routes.REPORTING}?campaign=${campaign.id}`}
          >
            <span>{t('reporting')}</span>
            <SvgIcon component={RightChevronIcon} viewBox="0 0 32 32" />
          </Link>
        </Grid>
      </AccessResolver>

      {formType === 'edit' && (
        <OverviewSection bottomDivider={false} label={t('budget')}>
          <CampaignOverviewUsedBudgetBar
            budget={campaign.budget ? Number(campaign.budget) : 0}
            currency={campaign?.currency?.symbol || ''}
            usedBudget={
              campaign.used_budget_total_cumulative
                ? Number(campaign.used_budget_total_cumulative)
                : 0
            }
          />
        </OverviewSection>
      )}
      {!formState.hiddenFields.includes('Incentives') && (
        <OverviewSection bottomDivider={false}>
          <Incentives
            external_campaign_id={campaign.external_campaign_id || ''}
            formState={formState}
            max_incentive_value={campaign.max_incentive_value}
          />
        </OverviewSection>
      )}
      {!formState.hiddenFields.includes('RejectReason') && (
        <OverviewSection label={t('reject_reason')} bottomDivider={false}>
          <span data-testid="reject-reason">{campaign.reject_reason}</span>
        </OverviewSection>
      )}
      {isBidModifierEnabled &&
        !formState.hiddenFields.includes('BidModifier') && (
          <CampaignOverviewBidModifier
            state={campaign.state}
            bidModifier={campaign.bid_modifier}
          />
        )}
      {formState.formActions.some(
        (action: CampaignFormActionType) => action !== 'clone'
      ) && (
        <>
          <Divider />
          <OverviewSection bottomDivider={false} topDivider={false}>
            <Stack
              className={styles.bottom_action_bar}
              direction="row"
              spacing={1}
              justifyContent="flex-end"
              alignItems="center"
            >
              {children}
            </Stack>
          </OverviewSection>
        </>
      )}
    </Card>
  );
};
